import { AnimationTriggerMetadata, group, query, style, transition, trigger, useAnimation } from "@angular/animations";
import {
    bounce, bounceIn, bounceOut, fadeIn, flip, slideInRight, slideOutLeft,
    swing, fadeOut, flipInX, flipOutX, zoomOut, zoomIn, zoomOutRight, zoomOutLeft, zoomInLeft, zoomInRight
} from "ng-animate"; // https://jiayihu.github.io/ng-animate/

const fadeInOutAnimation = trigger("fadeInOut", [
    transition(":enter", useAnimation(fadeIn, { params: { timing: .3 } })),
    transition(":leave", useAnimation(fadeOut, { params: { timing: .3 } }))
]);

const fadeInOutSlowAnimation = trigger("fadeInOutSlow", [
    transition(":enter", useAnimation(fadeIn, { params: { timing: 0.5 } })),
    transition(":leave", useAnimation(fadeOut, { params: { timing: 0.5 } }))
]);

const bounceAnimation = trigger("bounce", [transition("* => *", useAnimation(bounce))]);
const bounceInAnimation = trigger("bounceIn", [transition("* => *", useAnimation(bounceIn))]);
const bounceInOnEnterAnimation = trigger("bounceInOnEnter", [transition(":enter", useAnimation(bounceIn))]);

const bounceOutAnimation = trigger("bounceOut", [transition("* => *", useAnimation(bounceOut))]);
const bounceOutOnLeaveAnimation = trigger("bounceOutOnLeave", [transition(":leave", useAnimation(bounceOut))]);

const flipOnAnimation = trigger("flip", [transition("* => *", useAnimation(flip))]);

const slideInOutRightAnimation = trigger("slideInOutRight", [
    transition("* => *", [
        query(":enter, :leave", style({ position: "fixed", width: "100%" }), { optional: true }),
        group([
            query(":leave", [useAnimation(slideOutLeft, { params: { timing: .3 } })], { optional: true }),
            query(":enter", [useAnimation(slideInRight, { params: { timing: .3 } })], { optional: true }),
        ])
    ])
]);

const zoomInOutRightAnimation = trigger("zoomInOutRight", [
    transition(":enter", useAnimation(zoomInRight, { params: { timing: 0.5 } })),
    transition(":leave", useAnimation(zoomOutRight, { params: { timing: 0.5 } })),
]);

const zoomInOutLeftAnimation = trigger("zoomInOutLeft", [
    transition(":enter", useAnimation(zoomInLeft, { params: { timing: 0.5 } })),
    transition(":leave", useAnimation(zoomOutLeft, { params: { timing: 0.5 } })),
]);

const swingOnAnimation = trigger("swingOn", [transition("* => *", useAnimation(swing))]);

export const sharedAnimationList: AnimationTriggerMetadata[] = [
    fadeInOutAnimation, fadeInOutSlowAnimation, bounceInAnimation,
    bounceAnimation, bounceInOnEnterAnimation, bounceOutAnimation, bounceOutOnLeaveAnimation,
    zoomInOutRightAnimation, zoomInOutLeftAnimation,
    flipOnAnimation, slideInOutRightAnimation, swingOnAnimation
];