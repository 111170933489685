import { formatDateToLocal } from "../../../shared/utilities";
import { PIOValidationError, PIOPowerDay, PIOValidationResult, PIOPowerDayDetail, PIO } from "../contract";
import { PIOValidationErrorViewModel, PIOPowerDayDetailViewModel, PIOValidationResultViewModel, PIOViewModel, PIOPowerDayViewModel } from "../view-model";

export function mapToPIOPowerDayDetailViewModel(powerDayDetails: PIOPowerDayDetail[]): PIOPowerDayDetailViewModel[] {
    return powerDayDetails.sort((t1, t2) => t1.periodStart > t2.periodStart ? 1 : -1)
        .map(powerDayDetail => ({
            periodStart: new Date(powerDayDetail.periodStart),
            legendIndex: powerDayDetail.legendIndex,
            value: powerDayDetail.value,
            intradayValue: powerDayDetail.intradayValue,
            valueSource: powerDayDetail.valueSource,
            marketCategory: powerDayDetail.marketCategory,
            originalValue: powerDayDetail.originalValue,
            originalIntradayValue: powerDayDetail.originalIntradayValue
        }));
}

export function mapToPIOPowerDayViewModel(powerDays: PIOPowerDay[]): PIOPowerDayViewModel[] {
    return powerDays.map(powerDay => ({
        powerDayStartUtc: new Date(powerDay.powerDayStart),
        powerDayStartCET: formatDateToLocal(powerDay.powerDayStart, "Europe/Copenhagen", "DD-MMM"),
        details: mapToPIOPowerDayDetailViewModel(powerDay.details)
    }));
}

export function mapToPIOViewModel(pio: PIO): PIOViewModel {
    if (!pio) {
        return {
            legendsUtcTimes: [],
            powerDayData: []
        };
    }

    return {
        legendsUtcTimes: pio.legendsUtcTimes,
        powerDayData: mapToPIOPowerDayViewModel(pio.powerDayData.sort((t1, t2) => t1.powerDayStart > t2.powerDayStart ? 1 : -1))
    };
}

export function mapToPIOValidationErrorViewModel(validationErrors: PIOValidationError[]): PIOValidationErrorViewModel[] {
    return validationErrors.map(o => ({ error: o.error, lineNumber: o.lineNumber, source: o.source }));
}

export function mapToValidationResponseViewModel(validationResult: PIOValidationResult): PIOValidationResultViewModel {
    return {
        validationErrors: mapToPIOValidationErrorViewModel(validationResult.validationErrors),
        validatedContent: mapToPIOViewModel(validationResult.validatedContent)
    };
}

