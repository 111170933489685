import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../services";

@Component({
  selector: "app-toolbar-user-info",
  templateUrl: "./toolbar-user-info.component.html",
  styleUrls: ["./toolbar-user-info.component.scss"]
})
export class ToolbarUserInfoComponent implements OnInit {

  public fullName: string;
  public userName: string;

  constructor(public readonly httpService: HttpService) { }

  ngOnInit(): void {
    setTimeout(() => {
      var account = this.httpService.getCurrentUser();
      this.fullName = account?.name;
      this.userName = account?.username;       
    }, 5000);
  }

  logout() {
    this.httpService.logout();
  }

}
