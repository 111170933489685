import { ComponentType } from "@angular/cdk/overlay/index";
import { Injectable, Provider, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { ConfirmDialogComponent, DisplayDialogComponent } from "../components/index";

@Injectable({
  providedIn: "root"
})
export class DialogService {
  constructor(private readonly dialog: MatDialog) { }

  confirm(header: string, text: string, onOk: () => void, onCancel = () => { }) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "550px",
      data: { header: header, text: text }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        onOk();
      } else {
        onCancel();
      }
    });
  }

  display(header: string, text: string) {
    const dialogRef = this.dialog.open(DisplayDialogComponent, {
      width: "850px",
      data: { header: header, text: text }
    });
  }

  displayComponent<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, width: number = 250, data: any, onClosded: (r: any) => void) {
    var dialog = this.dialog.open(componentOrTemplateRef, {
      width: width + "px",
      data: data
    });

    dialog.afterClosed().pipe(take(1)).subscribe(data => { if(data) onClosded(data); });

    return dialog;
  }
}

export const dialogServiceProvider: Provider = {
  provide: DialogService,
  useClass: DialogService,
  deps: [MatDialog]
};
