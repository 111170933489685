import { Action } from "@ngrx/store";

export enum CommunicationActionTypes {
  HttpRequestStarted = "[Communication] Http request started",
  HttpRequestCompleted = "[Communication] Http request completed",
  HttpRequestError = "[Communication] Http request error"
}

export class HttpRequestStartedAction implements Action {
  readonly type = CommunicationActionTypes.HttpRequestStarted;
  constructor(public readonly requestId: string, public url: string, public data: any) { }
}

export class HttpRequestCompletedAction implements Action {
  readonly type = CommunicationActionTypes.HttpRequestCompleted;
  constructor(public readonly requestId: string) { }
}

export class HttpRequestErrorAction implements Action {
  readonly type = CommunicationActionTypes.HttpRequestError;
  constructor(
    public readonly requestId: string,
    public readonly errorCode: number,
    public readonly errorText: string
  ) { }
}

export type CommunicationActions =
  | HttpRequestStartedAction
  | HttpRequestCompletedAction
  | HttpRequestErrorAction;
