import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { filter, switchMap, withLatestFrom } from "rxjs/operators";

import { HttpService } from "../../communication/services";
import { AlertService, DialogService } from "../../shared/services";

import { PIOValidationResult, PIO, Site } from "./contract";
import {
    AvailabilityFeatureActionTypes, ValidatePIOAction, PIOValidatedAction, SubmitPIOAction,
    PIOSubmittedAction, ActiveSiteAction, GetPIOHistoryAction, PIOHistoryAction, GetSitesAction, SitesAction
} from "./feature.actions";
import { State } from "./state";
import { mapToValidationResponseViewModel, mapToPIOViewModel } from "./utilities";
import { selectFileContent, selectActiveSite, selectChanged, selectHistoryDates, selectSites, selectHistoryVisible } from "./feature.selectors";


@Injectable()
export class PIOEffects {

    constructor(
        private readonly httpService: HttpService,
        private readonly actions$: Actions,
        private readonly store: Store<State>,
        private readonly alertService: AlertService,
        private readonly dialogService: DialogService,
        private readonly router: Router
    ) { }

    
    getAssets$ = createEffect(() => this.actions$.pipe(
        ofType<GetSitesAction>(AvailabilityFeatureActionTypes.GetSites),        
        switchMap(action => this.httpService.get<Site[]>("/api/Availability/sites")),
        switchMap(data => [new SitesAction(data), new ActiveSiteAction(data[0]?.id) ])
    ));

    
    validatePIO$ = createEffect(() => this.actions$.pipe(
        ofType<ValidatePIOAction>(AvailabilityFeatureActionTypes.ValidatePIO),
        withLatestFrom(this.store.select(selectActiveSite)),
        switchMap(([action, activeSite]) =>
            this.httpService.post<PIOValidationResult>("/api/Availability/submit", { siteId: activeSite.id, assetId: activeSite.assetId, fileContent: action.fileContent, validateOnly: true })),
        switchMap(data => [new PIOValidatedAction(mapToValidationResponseViewModel(data))])
    ));

    
    submitPIO$ = createEffect(() => this.actions$.pipe(
        ofType<SubmitPIOAction>(AvailabilityFeatureActionTypes.SubmitPIO),
        withLatestFrom(this.store.select(selectActiveSite), this.store.select(selectFileContent)),
        switchMap(([submitAction, activeSite, pioFileContent]) =>
            this.httpService.post<PIOValidationResult>("/api/Availability/submit", { siteId: activeSite.id, assetId: activeSite.assetId, fileContent: pioFileContent, validateOnly: false })),
        switchMap(data => {
            if (data.validationErrors.length === 0)
                this.alertService.success("Data submitted successfully");            
            return [new PIOSubmittedAction(mapToValidationResponseViewModel(data))];
        })));

    
    getPIOHistory$ = createEffect(() => this.actions$.pipe(
        ofType<GetPIOHistoryAction>(AvailabilityFeatureActionTypes.GetPIOHistory),
        withLatestFrom(this.store.select(selectActiveSite), this.store.select(select)),
        switchMap(([getPIOHistoryAction, activeSite]) => this.httpService.get<PIO>("/api/Availability/history", { siteId: activeSite.id, assetId: activeSite.assetId, fromTimeUtc: getPIOHistoryAction.from.toJSON(), endTimeUtc: getPIOHistoryAction.to.toJSON() })),
        switchMap(data => [new PIOHistoryAction(mapToPIOViewModel(data))])));

    
    refreshPIOHistory$ = createEffect(() => this.actions$.pipe(
        ofType<ActiveSiteAction>(AvailabilityFeatureActionTypes.ActiveSite),
        withLatestFrom(this.store.select(selectSites), this.store.select(selectHistoryDates), this.store.select(selectHistoryVisible)),
        filter(([activeSiteAction, sites, historyDates, historyVisible]) => historyVisible === true && historyDates.fromUtc !== undefined),
        switchMap(([activeSiteAction, sites, historyDates, historyVisible]) => this.httpService.get<PIO>("/api/Availability/history", { siteId: activeSiteAction.siteId, assetId: sites.find(s => s.id == activeSiteAction.siteId).assetId, fromTimeUtc: historyDates.fromUtc.toJSON(), endTimeUtc: historyDates.toUtc.toJSON() })),
        switchMap(data => [new PIOHistoryAction(mapToPIOViewModel(data))])));
}
