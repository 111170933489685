import { utc, duration, unix, tz } from "moment-timezone";

export function getUKDate(date = new Date()): string {
    return formatDateToLocal(date, "Europe/London", "DD-MM-YYYY");
}

export function getUKTime(date = new Date()): string {
    const result = new Date(date);

    return formatTimeToLocal(result, "Europe/London", "HH:mm");
}

export function nextMarketClosingUtcDateTime(): Date {
    const closingDateTime = localDateToUtcDateWithFormat(getUKDate() + " 09:00:00", "DD-MM-YYYY HH:mm", "Europe/London");
    const result = localDateToUtcDateWithFormat(getUKDate() + " 22:59:59", "DD-MM-YYYY HH:mm", "Europe/London");
    if ((new Date()).getTime() > closingDateTime.getTime()) {
        return addHoursToDate(result, 24, 0, 0);
    } else {
        return result;
    }
}

export function isAvailabilityDeadlineExceeded(forecastDate: Date) {
    const nextMarketClosingDate = nextMarketClosingUtcDateTime();

    return forecastDate.getTime() <= nextMarketClosingDate.getTime();
}

export const addDaysToDate = (date: Date, days: number) => addHoursToDate(date, days * 24);

export const addHoursToDate = (date: Date, hours: number, minutes: number = 0, seconds: number = 0) => {
    const result = new Date(date);
    result.setTime(result.getTime() + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000));
    return result;
}

export function validDate(date: Date | string, timezone: string): boolean {
    return tz(new Date(date), timezone).isValid();
}

export function validTime(value: string): boolean {
    const regEx = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
    return regEx.test(value);
}

export function getCurrentLocalDate(local: string): Date {
    const result = tz(local).toDate();
    return result;
}

export function getCurrentUTCDate(): Date {
    return new Date(new Date().toUTCString());
}

export function utcDateToLocalDate(utcDate: Date | string, local: string): Date {
    return utc(utcDate).tz(local).toDate();
}

export function localDateToUtcDate(localDate: Date | string, local: string): Date {
    return tz(localDate, local).utc().toDate();
}

export function localDateToUtcDateWithFormat(utcDate: string, format: string, local: string): Date {
    return tz(utcDate, format, local).utc().toDate();
}

export function shiftlocalDateAsUtc(utcDate: Date | string, local: string) : Date {
    return localDateToUtcDateWithFormat(formatDateToLocal(utcDate, "utc", "YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm", local);
}

export function formatDateToLocal(date: Date | string, local: string, dateFormat: string): string {
    const utcDate = utc(date);
    const result = utcDate.tz(local).format(dateFormat);
    return result;
}

export function formatTimeToLocal(date: Date | string, local: string, timeFormat: string): string {
    const utcDate = utc(date);
    const result = utcDate.tz(local).format(timeFormat);
    return result;
}

export function unixTimeToDate(unixTime: number): Date {
    return new Date(unixTime * 1000);
}

export function dateToUnixTime(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}

export function currentUnixTime(date: Date = new Date()): number {
    return dateToUnixTime(new Date());
}

export function stringToDate(value: string): Date {
    if (value[value.length - 1] === "Z" || value.indexOf("+") >= 0) {
        return new Date(value);
    }

    return new Date(value + "z");
}

export function dateDiffInSeconds(from: Date, to: Date): number {
    const diff = utc(from).diff(utc(to));
    const durationInSecond = Math.floor(Math.abs(duration(diff).asSeconds()));
    return durationInSecond;
}

export function dateDiffInMinutes(from: Date, to: Date): number {
    const diff = utc(from).diff(utc(to));
    const durationInSecond = Math.floor(Math.abs(duration(diff).asMinutes()));
    return durationInSecond;
}

export function unixDateDiffInSeconds(from: number, to: number): number {
    const diff = unix(from).diff(unix(to));
    const durationInSecond = Math.floor(Math.abs(duration(diff).asSeconds()));
    return durationInSecond;
}

export function unixDateDiffInMinutes(from: number, to: number): number {
    const diff = unix(from).diff(unix(to));
    const durationInSecond = Math.floor(Math.abs(duration(diff).asMinutes()));
    return durationInSecond;
}

export function getGasIntradayDate() {
    return localDateToUtcDateWithFormat(getUKDate() + " 05:00:00", "DD-MM-YYYY HH:mm", "Europe/London");
}

export function getGasDayAheadDate() {
    const tomorrowDate = addHoursToDate(new Date(), 24, 0, 0);
    return localDateToUtcDateWithFormat(getUKDate(tomorrowDate) + " 05:00:00", "DD-MM-YYYY HH:mm", "Europe/London");
}

export function getTradeIntradayDate() {
    return localDateToUtcDateWithFormat(utc(new Date()).format("DD-MM-YYYY") + " 00:00:00", "DD-MM-YYYY HH:mm", "CET");
}

export function getTradeDayAheadDate() {
    const tomorrowDate = addHoursToDate(new Date(), 24, 0, 0);
    return localDateToUtcDateWithFormat(utc(new Date()).format("DD-MM-YYYY") + " 00:00:00", "DD-MM-YYYY HH:mm", "CET");
}