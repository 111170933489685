import { Component, OnInit } from "@angular/core";

import { PIOService } from "../../services";

@Component({
  selector: "app-pio-submit",
  templateUrl: "./pio-submit.component.html",
  styleUrls: ["./pio-submit.component.scss"]
})
export class PIOSubmitComponent implements OnInit {
  dragInside = false;

  constructor(public readonly pioService: PIOService) { }

  ngOnInit() {
  }

  submitPIO() {
    this.pioService.submitPIOChanges();
  }

  showHistory() {
    this.pioService.showHistory();
  }

  onFileSelected(fileList: File[]) {
    this.dragInside = false;
    if (fileList.length > 0) {
      const reader = new FileReader();
      reader.onload = (evt: any) =>
        this.pioService.validatePIO(evt.target.result);
      reader.readAsText(fileList[0]);
    }
  }

}
