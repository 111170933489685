import { Component } from "@angular/core";
import { sharedAnimationList } from "../../animations/index";

@Component({
  selector: "app-play-ground",
  templateUrl: "./play-ground.component.html",
  styleUrls: ["./play-ground.component.scss"],
  animations: sharedAnimationList
})
export class PlayGroundComponent {
  visible1 = true;
  visible2 = true;

  constructor() {
  }


}
