import { Component } from "@angular/core";
import { sharedAnimationList } from "../../../shared/animations";
import { PIOService } from "../../services";

@Component({
  selector: "app-pio",
  templateUrl: "./pio.component.html",
  styleUrls: ["./pio.component.scss"],
  animations: [sharedAnimationList]
})
export class PIOComponent {
  historyVisible = false;

  constructor(public readonly pioService: PIOService) { }

  onActiveSiteChanged(assetId: string) {
    this.pioService.changeActiveAsset(assetId);
  }

}
