export function copyObject(srcObject) {
    return JSON.parse(objectToString(srcObject));
}

export function objectToString(obj: any): string {
    let result = "";
    try {
        const cache = [];
        result = JSON.stringify(obj, (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Duplicate reference found
                    try {
                        // If this value does not reference a parent it can be deduped
                        return JSON.parse(JSON.stringify(value));
                    } catch (error) {
                        // discard key if value cannot be deduped
                        return;
                    }
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
    } catch {
    }

    return result;
}

export function isObjectsEqual(obj1, obj2): boolean {
    return (objectToString(obj1) === objectToString(obj2));
}