import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-toolbar-uk-local-time",
  templateUrl: "./toolbar-uk-local-time.component.html",
  styleUrls: ["./toolbar-uk-local-time.component.scss"]
})
export class ToolbarUkLocalTimeComponent implements OnInit {

  @Input() UKLocalDate: string;
  @Input() UKLocalTime: string;

  constructor() { }

  ngOnInit() {
  }

}
