import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { formatDateToLocal, shiftlocalDateAsUtc } from "../../../shared/utilities";
import { PIOService } from "../../services";
import { PIOMarketCategoryEnum } from "../../state/contract";
import { PIOPowerDayDetailViewModel } from "../../state/view-model";

@Component({
  selector: "app-pio-submit-chart",
  templateUrl: "./pio-submit-chart.component.html",
  styleUrls: ["./pio-submit-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PIOSubmitChartComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];  

  dataSets$: Observable<any[]> = this.pioService.pioPowerDaysFlatten$.pipe(
    map(validationResult => {

      const result: any[] = [];

      let firstDayAheadIndex = validationResult.findIndex(row => row.marketCategory === PIOMarketCategoryEnum.DayAhead);
      if (firstDayAheadIndex === null) {
        firstDayAheadIndex = validationResult.length;
      }

      let firstIntradayIndex = validationResult.findIndex(row => row.marketCategory === PIOMarketCategoryEnum.Intraday);
      if (firstIntradayIndex === null) {
        firstIntradayIndex = firstDayAheadIndex;
      }

      result.push({
        label: "Day Ahead",
        type: "line",
        fill: false,
        data: validationResult.map(row => ({ x: shiftlocalDateAsUtc(row.periodStart, Intl.DateTimeFormat().resolvedOptions().timeZone), y: row.marketCategory === PIOMarketCategoryEnum.DayAhead ? row.value : undefined })),
        backgroundColor: "#9acdff",
        borderColor: "#9acdff",
        interpolate: true
      });

      result.push(
        {
          label: "Intraday",
          type: "line",
          fill: false,
          data: validationResult.map((row, index) => ({ x: shiftlocalDateAsUtc(row.periodStart, Intl.DateTimeFormat().resolvedOptions().timeZone), y: row.marketCategory === PIOMarketCategoryEnum.Intraday ? (row.intradayValue !== null ? row.intradayValue : row.value) : firstDayAheadIndex === index ? validationResult[index].value : undefined })),
          backgroundColor: "#dbb9ff",
          borderColor: "#dbb9ff",
          interpolate: true
        });


      result.push({
        label: "Expired",
        type: "line",
        fill: false,
        data: validationResult.map((row, index) => ({ x: shiftlocalDateAsUtc(row.periodStart, Intl.DateTimeFormat().resolvedOptions().timeZone), y: this.getExpiredData(validationResult, row, index, firstIntradayIndex) })),
        backgroundColor: "#cccccc",
        borderColor: "#cccccc",
        interpolate: true
      });

      return result;
    }));

  label$ = this.pioService.pioPowerDaysFlatten$.pipe(map(rows => rows.map(row => shiftlocalDateAsUtc(row.periodStart, Intl.DateTimeFormat().resolvedOptions().timeZone))));

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },    
    elements: {
      point: {
        radius: 1,
        hoverRadius: 5,
      }
    },
    plugins: {
      datalabels: {
        display: false
      },
      legend: {
        display: true,
      },
      crosshair: {
        enabled: true,
        line: {
          color: "#F66",  // crosshair line color
          width: 1,   // crosshair line width
        },
        sync: {
          enabled: false
        },
        group: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      tooltip: {
        mode: "index",
        intersect: false
      },
    },
    layout: {
      padding: {
        top: 30
      }
    },
    scales: {
      x: {
        type: "time",
        distribution: "series",
        time: {
          unit: "minute",
          tooltipFormat: "DD-MM-YYYY HH:mm",
          displayFormats: {
            minute: "DD-MM-YYYY HH:mm"
          }
        },
        ticks: {
          beginAtZero: false,
          source: "labels",
          autoSkip: true
        },
        stacked: false,
        grid: {
          offset: false,
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: true,
        }
      },
      y: {
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: "MW",
          fontSize: 15,
        },
        ticks: {
          display: true,
          beginAtZero: true,
        },
        grid: {
          offset: false,
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true
        }
      }
    }
  };

  private getExpiredData(validationResult: PIOPowerDayDetailViewModel[], row: PIOPowerDayDetailViewModel, index: number, firstIntradayIndex: number) {
    let result: number;

    if (row.marketCategory === PIOMarketCategoryEnum.Expired) {
      result = row.intradayValue !== null ? row.intradayValue : row.value;
    } else if (firstIntradayIndex === index) {
      result = validationResult[index].intradayValue !== null ? validationResult[index].intradayValue : validationResult[index].value;
    }

    return result;
  }

  constructor(private readonly pioService: PIOService) {
    this.subscriptions.push(this.pioService.pioPowerDaysFlatten$.subscribe(o => {
      const annotation = {
        drawTime: "afterDatasetsDraw",
        annotations: []
      };
      const intraday = o.find(t => t.marketCategory === PIOMarketCategoryEnum.Intraday);
      if (intraday) {
        annotation.annotations.push(
          {
            id: "Intraday",
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: intraday.periodStart,
            borderColor: "#dbb9ffd1",
            borderDash: [10, 10],
            borderWidth: 2,
            label: {
              backgroundColor: "#dbb9ffd1",
              content: "ID: " + formatDateToLocal(intraday.periodStart, "UTC", "DD-MM-YYYY HH:mm"),
              enabled: true,
              position: "top",
              xAdjust: 60
            }
          }
        );
      }
      const dayahead = o.find(t => t.marketCategory === PIOMarketCategoryEnum.DayAhead);
      if (dayahead) {
        annotation.annotations.push(
          {
            id: "DayAhead",
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: dayahead.periodStart,
            borderColor: "#9acdffe1",
            borderDash: [10, 10],
            borderWidth: 2,
            label: {
              backgroundColor: "#9acdffe1",
              content: "DA: " + formatDateToLocal(dayahead.periodStart, "UTC", "DD-MM-YYYY HH:mm"),
              enabled: true,
              position: "top",
              xAdjust: -60
            }
          }
        );
      }

      this.chartOptions["annotation"] = annotation;
    })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
