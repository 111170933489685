export const environment = {
  application_version: '"__build_buildnumber__"',
  production: true,
  serviceWorker: false,
  backendAddress: '"__Availability_backend_url__"',
  appInsights: {
    instrumentationKey: '"__instrumentationKey__"'
  },
  msal: {
    clientId: '"__Availability_UI_ClientId__"',
    authority: '"__Availability_Authority__"',
    validateAuthority: true,
    msalInterceptor: {
      '"__Availability_backend_url__"': ['"__Availability_scope__"']
    }
  }
};
