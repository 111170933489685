import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Provider } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, finalize, map, switchMap } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { objectToString, Guid, newGuid } from "../../shared/utilities";
import { AppInsightService, DialogService } from "../../shared/services";
import { HttpRequestCompletedAction, HttpRequestErrorAction, HttpRequestStartedAction } from "../state/communication.actions";
import { selectHttp, State } from "../state/communication.reducer";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-common";

@Injectable({
  providedIn: "root"
})
export class HttpService {
  state$ = this.store.pipe(select(selectHttp));
  networkNumberOfRequests$ = this.state$.pipe(map(httpState => httpState.NumberOfRequests));
  activeNetworkRequests$ = this.networkNumberOfRequests$.pipe(map(requestCount => requestCount > 0));

  constructor(
    private readonly store: Store<State>,
    private readonly httpClient: HttpClient,
    private readonly dialogService: DialogService,
    private readonly appInsightService: AppInsightService,
    public readonly msalService: MsalService
  ) { }

  getCurrentUser(): AccountInfo {
    return this.msalService.instance.getAllAccounts()[0];       
  }

  logout() {
    this.msalService.loginRedirect();
  }

  get<T>(relativeUrl: string, data?: any, raiseError: boolean = false): Observable<T> {
    const url = environment.backendAddress + relativeUrl;
    const requestId = newGuid();
    this.store.dispatch(new HttpRequestStartedAction(requestId, url, data));
    return this.httpClient.get<T>(url, { params: data })
                          .pipe(
                            finalize(() => {
                              this.store.dispatch(new HttpRequestCompletedAction(requestId));
                            }),
                            catchError((err: HttpErrorResponse) => {
                              this.appInsightService.logException(err, "HttpService Get", { exceptionType: "HttpService Get Error", "url": url, error_text: objectToString(err) });
                              this.store.dispatch(
                                new HttpRequestErrorAction(requestId, err.status, err.statusText)
                              );

                              this.dialogService.display(err.statusText, relativeUrl + "\n" + "\n" + err.message);

                              if (raiseError) {
                                throw err;
                              }

                              return of<any>();
                            })
                          );
  }

  post<T>(relativeUrl: string, data: any, raiseError: boolean = false): Observable<T> {
    const url = environment.backendAddress + relativeUrl;
    const requestId = newGuid();
    this.store.dispatch(new HttpRequestStartedAction(requestId, url, data));

    return this.httpClient.post<T>(url, data)
                          .pipe(finalize(() => this.store.dispatch(new HttpRequestCompletedAction(requestId))),
                                catchError((err: HttpErrorResponse) => {
                                  this.appInsightService.logException(err, "HttpService Post", { exceptionType: "HttpService Post Error", url: url, error_text: objectToString(err) });
                                  this.store.dispatch(
                                    new HttpRequestErrorAction(requestId, err.status, err.statusText)
                                  );
                                  this.dialogService.display(err.statusText, relativeUrl + "\n" + "\n" + err.message);

                                  if (raiseError) {
                                    throw err;
                                  }

                                  return of<any>();
                                })
                              );      
  }
}

export const httpServiceProvider: Provider = {
  provide: HttpService,
  useClass: HttpService,
  deps: [Store, HttpClient, DialogService, AppInsightService, MsalService]
};
