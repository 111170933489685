<mat-toolbar class="mat-elevation-z1">
  <mat-toolbar-row>

    <app-toolbar-settings-menu></app-toolbar-settings-menu>

    <app-toolbar-divider></app-toolbar-divider>

    <app-toolbar-brand-button></app-toolbar-brand-button>

    <app-toolbar-divider></app-toolbar-divider>

    <app-toolbar-pio-button>
    </app-toolbar-pio-button>    

    <div class="flex-filling-gap"></div>

    <app-toolbar-divider></app-toolbar-divider>

    <div class="gas-time-info">      
      <app-toolbar-uk-local-time [UKLocalDate]="dateService.currentUKLocalDate$ | async"
        [UKLocalTime]="dateService.currentUKLocalTime$ | async">
      </app-toolbar-uk-local-time>
    </div>

    <app-toolbar-divider></app-toolbar-divider>

    <app-toolbar-user-info></app-toolbar-user-info>

  </mat-toolbar-row>
</mat-toolbar>