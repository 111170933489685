import { Component } from "@angular/core";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { DateService } from "../../../shared/services";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent  {

  constructor(
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly dateService: DateService
  ) {
  }

}
