import { Injectable, Provider } from "@angular/core";


@Injectable({
  providedIn: "root"
})
export class AppService {

  constructor() {
  }

}

export const appServiceProvider: Provider = {
  provide: AppService,
  useClass: AppService,
  deps: []
};
