<div class="flex-row-direction">
    <div style="background-color: blue; width: 100px; height: 100px;" *ngIf="visible1" [@zoomInOutLeft]>
        test
    </div>
    <button (click)="visible1 = !visible1">Test</button>
</div>

<div class="flex-row-direction">
    <div style="background-color: red; width: 100px; height: 100px;" *ngIf="visible2" [@zoomInOutRight]>
        test
    </div>
    <button (click)="visible2 = !visible2">Test</button>
</div>