import { Component, OnInit } from "@angular/core";
import { PIOService } from "../../services";
import { PIOMarketCategoryEnum, PIOValueSourceEnum } from "../../state/contract";

@Component({
  selector: "app-pio-submit-detail",
  templateUrl: "./pio-submit-detail.component.html",
  styleUrls: ["./pio-submit-detail.component.scss"]
})
export class PIOSubmitDetailComponent {
  PIOMarketCategoryEnum = PIOMarketCategoryEnum;
  PIOValueSourceEnum = PIOValueSourceEnum;

  constructor(public readonly pioService: PIOService) {
  }

}
