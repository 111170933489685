import { Component, HostBinding, HostListener } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ProgressBarMode } from "@angular/material/progress-bar";
import { debounceTime } from "rxjs/operators";
import { HttpService } from "../../services";

@Component({
  selector: "app-network-connection-progress-bar",
  templateUrl: "./network-connection-progress-bar.component.html",
  styleUrls: ["./network-connection-progress-bar.component.scss"]
})
export class NetworkConnectionProgressBarComponent {

  visible = false;
  mode: ProgressBarMode = 'determinate';
  value = 0;
  color: ThemePalette = 'primary';

  constructor(
    public readonly httpService: HttpService
  ) {
    this.httpService.activeNetworkRequests$.pipe(debounceTime(10)).subscribe(value => {
      this.mode = (value) ? "query" : "determinate";      
      this.visible = value;
    });
  }

}
