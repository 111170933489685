import { Injectable, Provider } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root"
})
export class AlertService {

  constructor(private snackBar: MatSnackBar) { }

  success(message: string) {
    this.snackBar.open(message, "Close", {
      duration: 5000,
      verticalPosition: "top"
    });
  }

  info(message: string) {
    this.snackBar.open(message, "Close", {
      duration: 5000,
      verticalPosition: "top"
    });
  }

}

export const alertServiceProvider: Provider = {
  provide: AlertService,
  useClass: AlertService,
  deps: [MatSnackBar]
};
