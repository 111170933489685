import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../shared/shared.module";

import {
  PIOComponent,
  ToolbarPIOButtonComponent,
  UploadFileComponent,
  PIOSubmitComponent, PIOSubmitChartComponent, PIOSubmitDetailComponent, PIOSubmitErrorsComponent,
  PIOHistoryComponent, PIOHistoryChartComponent
} from "./components";
import { pioServiceProvider } from "./services";
import { PIOEffects } from "./state/feature.effects";
import { reducer } from "./state/feature.reducer";
import { featureName } from "./state/feature.selectors";


const routes: Routes = [
  {
    path: "", component: PIOComponent, canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [
    StoreModule.forFeature(featureName, reducer),
    EffectsModule.forFeature([PIOEffects]),
    RouterModule.forChild(routes),
    SharedModule
  ],
  declarations: [
    PIOComponent,
    UploadFileComponent,
    ToolbarPIOButtonComponent,
    PIOSubmitComponent,
    PIOSubmitChartComponent,
    PIOSubmitErrorsComponent,
    PIOSubmitDetailComponent,
    PIOHistoryComponent,
    PIOHistoryChartComponent
  ],
  exports: [
    ToolbarPIOButtonComponent
  ],
  providers: [
    pioServiceProvider
  ]
})
export class AvailabilityModule { }
