import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { SharedModule } from "../shared/shared.module";
import { environment } from "../../environments/environment";
import { NetworkConnectionProgressBarComponent, ToolbarUserInfoComponent } from "./components";
import { httpServiceProvider } from "./services";
import { featureName, reducer } from "./state/communication.reducer";

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msal.clientId,
      authority: environment.msal.authority,
      //redirectUri: environment.msal.redirectUri,
      //postLogoutRedirectUri: environment.msal.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  const msalConfig = environment.msal.msalInterceptor as any;
  for (const propertyName in msalConfig)
  {
    if (environment.msal.msalInterceptor.hasOwnProperty(propertyName))
    {
       protectedResourceMap.set(propertyName, msalConfig[propertyName]);
    }
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  imports: [
    SharedModule,
    MsalModule,
    StoreModule.forFeature(featureName, reducer)
  ],
  declarations: [    
    NetworkConnectionProgressBarComponent,
    ToolbarUserInfoComponent
  ],
  exports: [    
    NetworkConnectionProgressBarComponent,
    ToolbarUserInfoComponent
  ],
  providers: [    
    httpServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    MsalService
    ],
})
export class CommunicationModule {

}
