import { Component, OnInit, OnDestroy } from "@angular/core";
import { take, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { Moment, tz } from "moment";

import { PIOPowerDayDetailViewModel } from "../../state/view-model";
import { PIOService } from "../../services";
import { formatDateToLocal } from "../../../shared/utilities";

@Component({
  selector: "app-pio-history",
  templateUrl: "./pio-history.component.html",
  styleUrls: ["./pio-history.component.scss"]
})
export class PIOHistoryComponent implements OnInit, OnDestroy {

  fromDate: Moment;
  toDate: Moment;

  subscriptions: Subscription[] = [];

  constructor(public readonly pioService: PIOService) {
  }

  ngOnInit() {
    this.getTodayReport();
    this.subscriptions.push(
      this.pioService.pioActiveSite$.pipe(distinctUntilChanged()).subscribe(site => {
        this.getHistoryData();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getHistoryData() {
    if(!this.fromDate || !this.toDate)
      return;
    const from = tz(this.fromDate.toDate().toISOString().split("T").shift() + "T22:00Z", "utc");
    const to = tz(this.toDate.toDate().toISOString().split("T").shift() + "T22:00Z", "utc");
    this.pioService.getPIOHistory(from.toDate(), to.add(1, "days").toDate());
  }

  backClick() {
    this.pioService.hideHistory();
  }

  getTodayReport() {
    this.fromDate = tz(new Date(), "utc");
    this.toDate = tz(new Date(), "utc").add(2, "days");

    this.getHistoryData();
  }

  convertToCSV(historicalData: PIOPowerDayDetailViewModel[]) {
    let result = "";

    result += "Date(UTC),Time(UTC),DayAhead(MW),Intraday(MW)";

    result += "\r\n";

    historicalData.forEach(data => {
      const date = formatDateToLocal(data.periodStart, "utc", "YYYY-MM-DD");
      const time = formatDateToLocal(data.periodStart, "utc", "HH:mm");
      const value = data.value;
      const intradayValue = data.intradayValue !== null ? data.intradayValue : data.value;

      result += date + "," + time + "," + value + "," + intradayValue;

      result += "\r\n";
    });

    return result;
  }

  downloadHistoryClick() {
    this.pioService.pioHistoryData$.pipe(take(1)).subscribe(data => {
      const element = document.createElement("a");
      element.style.display = "none";

      const filename = this.fromDate.format("YYYYMMDD") + "-" + this.toDate.format("YYYYMMDD") + ".csv";
      element.setAttribute("download", filename);

      const csvText = this.convertToCSV(data);
      element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(csvText));

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }

}
