import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CommunicationActions, CommunicationActionTypes } from "./communication.actions";


export interface HttpState {
  NumberOfRequests: number;
  errors: string[];
}


export interface State {
  Http: HttpState;
}

export const initialState: State = {
  Http: {
    NumberOfRequests: 0,
    errors: []
  }
};

export function reducer(state = initialState, action: CommunicationActions): State {
  switch (action.type) {

    case CommunicationActionTypes.HttpRequestStarted:
      return { ...state, Http: { ...state.Http, NumberOfRequests: state.Http.NumberOfRequests + 1 } };

    case CommunicationActionTypes.HttpRequestCompleted:
      return {
        ...state, Http: { ...state.Http, NumberOfRequests: state.Http.NumberOfRequests - 1 }
      };

    case CommunicationActionTypes.HttpRequestError:
      return {
        ...state, Http: { ...state.Http, errors: state.Http.errors.concat(action.errorText) }
      };

    default:
      return state;
  }
}

export const featureName = "communication";

export const selectFeature = createFeatureSelector<State>(featureName);
export const selectHttp = createSelector(selectFeature, (state: State) => state.Http);

