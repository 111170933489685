import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../models/dialog-data";


@Component({
  selector: "app-display-dialog",
  templateUrl: "./display-dialog.component.html",
  styleUrls: ["./display-dialog.component.scss"]
})
export class DisplayDialogComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<DisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: DialogData
  ) {
    data.text = data.text.replace("\n", "<br/>");
  }
}
