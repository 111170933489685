<div (dragenter)="dragInside = true" (dragleave)="dragInside = false" class="flex-filling-gap flex-column-direction">
  <app-upload-file (fileSelected)="onFileSelected($event)" *ngIf="(pioService.pioActiveSite$ | async) !== undefined">
  </app-upload-file>

  <app-pio-submit-errors *ngIf="!dragInside && (pioService.pioResultErrors$ | async).length > 0"
    [errors]="pioService.pioResultErrors$ | async"></app-pio-submit-errors>

  <div class="pio-detail-row" *ngIf="!dragInside && pioService.pioChanged$ | async">
    <app-pio-submit-detail></app-pio-submit-detail>
    <app-pio-submit-chart></app-pio-submit-chart>
  </div>
  <div class="flex-row-direction flex-end-content flex-fixed-size" style="padding: 5px;">
    <button class="historical-button flex-align-start" mat-raised-button matTooltip="Historical view"
      (click)="showHistory()">
      <i class="material-icons">
        trending_up
      </i>
      History
    </button>

    <button [disabled]="!(pioService.pioCanSubmit$ | async)" color="primary" class="submit-button" mat-raised-button
      matTooltip="Submit changes" [disabled]="(pioService.pioCanSubmit$ | async) ? undefined : true"
      (click)="submitPIO()">
      <i class="material-icons">
        check_box
      </i>
      Submit
    </button>
  </div>
</div>