import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, ModuleWithProviders, Optional, SkipSelf } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle"
import { MatButtonModule } from "@angular/material/button"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatTreeModule } from "@angular/material/tree"
import { MatSortModule } from "@angular/material/sort"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { MatCardModule } from "@angular/material/card"
import { MatChipsModule } from "@angular/material/chips"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatExpansionModule } from "@angular/material/expansion"
import { MatMenuModule } from "@angular/material/menu"
import { MatRadioModule } from "@angular/material/radio"
import { MatSliderModule } from "@angular/material/slider"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { MatStepperModule } from "@angular/material/stepper"
import { MatTableModule } from "@angular/material/table"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatListModule } from "@angular/material/list"
import { MatDialogModule } from "@angular/material/dialog"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatTooltipModule } from "@angular/material/tooltip"
import { MatGridListModule } from "@angular/material/grid-list"
import { MatDividerModule } from "@angular/material/divider"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatBottomSheetModule } from "@angular/material/bottom-sheet"
import { MatSidenavModule } from '@angular/material/sidenav'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MsalGuard } from "@azure/msal-angular";

import {
  ConfirmDialogComponent, DisplayDialogComponent, SubmitDialogComponent,
  ToolBarDividerComponent, ToolbarUkLocalTimeComponent, AboutComponent, PlayGroundComponent, ChartComponent
} from "./components/index";
import { ClickStopPropagationDirective, NumberInputDirective } from "./directives/index";
import { UKLocalDatePipe, UKLocalTimePipe } from "./pipes/index";
import { dialogServiceProvider, dateServiceProvider, appInsightServiceProvider, customErrorHandlerProvider, alertServiceProvider } from "./services/index";
import { SharedConfiguration } from "./models/index";


const routes: Routes = [
  { path: "playground", component: PlayGroundComponent, pathMatch: "full", canActivate: [MsalGuard] }
];

@NgModule({
  declarations: [
    SubmitDialogComponent,
    ConfirmDialogComponent,
    DisplayDialogComponent,
    ToolBarDividerComponent,
    PlayGroundComponent,
    ToolbarUkLocalTimeComponent,
    AboutComponent,
    ChartComponent,

    // Directive
    NumberInputDirective,
    ClickStopPropagationDirective,

    // Pipe
    UKLocalDatePipe,
    UKLocalTimePipe
  ],
  imports: [
    RouterModule.forChild(routes),

    // Angular
    HttpClientModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    // Shared
    DragDropModule,

    NgxMaterialTimepickerModule,

    // Material Design
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,

    // Date
    MatMomentDateModule
  ],
  exports: [
    ToolBarDividerComponent,
    ToolbarUkLocalTimeComponent,
    AboutComponent,
    ConfirmDialogComponent,
    DisplayDialogComponent,
    ChartComponent,

    // Directive
    NumberInputDirective,
    ClickStopPropagationDirective,

    // Pipe
    UKLocalDatePipe,
    UKLocalTimePipe,

    NgxMaterialTimepickerModule,

    // Angular
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    // Material
    DragDropModule,

    // Material Design
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,

    // Date
    MatMomentDateModule
  ]
})
export class SharedModule {
  public static forRoot(configuration: SharedConfiguration): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        dialogServiceProvider,
        alertServiceProvider,
        customErrorHandlerProvider,
        appInsightServiceProvider,        
        dateServiceProvider,
        { provide: SharedConfiguration, useValue: configuration },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
    if (parentModule) {
      throw new Error("SharedModule is already loaded. Import in your base AppModule only.");
    }
  }
}
