<table mat-table [dataSource]="pioService.pioResultDetail$">

  <ng-container matColumnDef="timeUTC">
    <mat-header-cell *matHeaderCellDef>Time UTC</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.timeUTC }}
    </mat-cell>
  </ng-container>

  <ng-container *ngFor="let column of pioService.pioColumns$ | async" [matColumnDef]="column.powerDayCET">
    <mat-header-cell *matHeaderCellDef class="data-column">{{ column.powerDayCET }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="data-column" [ngClass]="{  
                    'expired' : row[column.powerDayCET]?.marketCategory === PIOMarketCategoryEnum.Expired, 
                    'dayAhead': row[column.powerDayCET]?.marketCategory === PIOMarketCategoryEnum.DayAhead, 
                    'intraday': row[column.powerDayCET]?.marketCategory === PIOMarketCategoryEnum.Intraday,
                    'added': row[column.powerDayCET]?.valueSource === PIOValueSourceEnum.Added,
                    'updated': row[column.powerDayCET]?.valueSource === PIOValueSourceEnum.Updated
                  }">
      {{ row[column.powerDayCET]?.intradayValue !== null ? row[column.powerDayCET]?.intradayValue : row[column.powerDayCET]?.value }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="pioService.pioColumnNames$ | async">
  </mat-header-row>

  <mat-row *matRowDef="let row; columns: pioService.pioColumnNames$ | async">
  </mat-row>

</table>