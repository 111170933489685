import { Component, OnInit, Inject } from "@angular/core";
import { SharedConfiguration } from "../../models/configuration";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})
export class AboutComponent implements OnInit {

  ApplicationVersion = "local";

  constructor(@Inject(SharedConfiguration) private readonly config: SharedConfiguration) {
    // filtering and finding application version
    const matched = /\d{4}\.\d{1,}\.\d{1,}\.\d{1,}/g.exec(this.config.applicationVersion);
    if (matched) {
      this.ApplicationVersion = matched[0];
    }
  }

  ngOnInit() {
  }

}
