import { Component, OnInit, Input } from "@angular/core";
import { PIOValidationErrorViewModel } from "../../state/view-model";

@Component({
  selector: "app-pio-submit-errors",
  templateUrl: "./pio-submit-errors.component.html",
  styleUrls: ["./pio-submit-errors.component.scss"]
})
export class PIOSubmitErrorsComponent implements OnInit {

  @Input()
  errors: PIOValidationErrorViewModel[];

  constructor() { }

  ngOnInit() {
  }

}
