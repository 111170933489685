import { Action } from "@ngrx/store";
import { Site } from "./contract";

import { PIOValidationResultViewModel, PIOViewModel } from "./view-model";

export enum AvailabilityFeatureActionTypes {
  GetSites = "[Availability Feature] Get Sites",
  Sites = "[Availability Feature] Sites",
  ActiveSite = "[Availability Feature] Active Site",

  GetPIOHistory = "[Availability Feature] Get PIO history",
  PIOHistory = "[Availability Feature] PIO history",
  ValidatePIO = "[Availability Feature] Validate PIO",
  PIOValidated = "[Availability Feature] PIO Validated",
  SubmitPIO = "[Availability Feature] Submit PIO",
  PIOSubmitted = "[Availability Feature] PIO submitted",
  SetPIOHistoryVisible = "[Availability Feature] Set PIO history visible",
}

export class GetSitesAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.GetSites;
  constructor() {
  }
}

export class SitesAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.Sites;
  constructor(public readonly assets: Site[]) {
  }
}

export class GetPIOHistoryAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.GetPIOHistory;
  constructor(public readonly from: Date, public readonly to: Date) { }
}

export class PIOHistoryAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.PIOHistory;
  constructor(public readonly history: PIOViewModel) { }
}

export class ActiveSiteAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.ActiveSite;
  constructor(public readonly siteId: string) { }
}

export class ValidatePIOAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.ValidatePIO;
  constructor(public readonly fileContent: string) { }
}

export class PIOValidatedAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.PIOValidated;
  constructor(
    public readonly validationResult: PIOValidationResultViewModel) {

  }
}

export class SubmitPIOAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.SubmitPIO;
  constructor() { }
}

export class PIOSubmittedAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.PIOSubmitted;
  constructor(
    public readonly validationResult: PIOValidationResultViewModel) {
  }
}

export class SetPIOHistoryVisibleAction implements Action {
  readonly type = AvailabilityFeatureActionTypes.SetPIOHistoryVisible;
  constructor(public readonly visible: boolean) { }
}


export type ForecastFeatureActions =
  | GetSitesAction
  | SitesAction
  | ActiveSiteAction
  | ValidatePIOAction
  | PIOValidatedAction
  | SubmitPIOAction
  | PIOSubmittedAction
  | SetPIOHistoryVisibleAction
  | GetPIOHistoryAction
  | PIOHistoryAction;
