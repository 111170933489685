import { ErrorHandler, Injectable, Injector, Provider } from "@angular/core";
import { DialogService } from "./dialog.service";
import { AppInsightService } from "./app-insight.service";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {
    }

    handleError(err: any) {
        const appInsightService: AppInsightService = this.injector.get(AppInsightService);
        appInsightService.logException(err, "CustomErrorHandler", {
            exceptionType: "CustomErrorHandler", error_text: err
        });
        console.error(err);
        
        if (err.rejection === "Failed to connect. Error: Error during negotiation request.") {
            return;
        }

        const dialogService: DialogService = this.injector.get(DialogService);
        let header = err.name;
        if (header === undefined) {
            header = "Runtime error";
        }

        let text = err.message;
        if (text === undefined) {
            text = err;
        } else if (err.stack !== undefined) {
            text = text + err.stack;
        }

        dialogService.display(header, text);
    }
}

export const customErrorHandlerProvider: Provider = {
    provide: ErrorHandler,
    useClass: CustomErrorHandler,
    deps: [Injector, DialogService]
};
