import { RouterNavigationAction, ROUTER_NAVIGATION } from "@ngrx/router-store";
import { tz } from "moment-timezone";

import { ForecastFeatureActions, AvailabilityFeatureActionTypes } from "./feature.actions";
import { initialState, State } from "./state";

export function reducer(
  state = initialState,
  action: ForecastFeatureActions | RouterNavigationAction
): State {
  switch (action.type) {

    case AvailabilityFeatureActionTypes.ActiveSite: {

      return {
        ...state,
        activeSiteId: action.siteId, 
        fileContent: "",
        validationResult: {
          validationErrors: [],
          validatedContent: {
            legendsUtcTimes: [],
            powerDayData: []
          }
        },
        historyData: {
          legendsUtcTimes: [],
          powerDayData: []
        }
      };       
    };

    case AvailabilityFeatureActionTypes.ValidatePIO: {
      return {
        ...state,
        fileContent: action.fileContent,
        validationResult: {
          validationErrors: [],
          validatedContent: {
            legendsUtcTimes: [],
            powerDayData: []
          }
        }
      };
    }

    case AvailabilityFeatureActionTypes.PIOValidated: {
      return {
        ...state,
        validationResult: {
          ...action.validationResult
        }
      };
    }

    case AvailabilityFeatureActionTypes.PIOSubmitted: {
      if (action.validationResult.validationErrors.length === 0) {
        return {
          ...state,
          fileContent: "",
          validationResult: {
            validationErrors: [],
            validatedContent: {
              legendsUtcTimes: [],
              powerDayData: []
            }
          }
        };
      } else {
        return {
          ...state,
          validationResult: {
            ...action.validationResult
          }
        };
      }
    }

    case AvailabilityFeatureActionTypes.GetPIOHistory: {
      return {
        ...state,
        historyDates: {fromUtc: action.from, toUtc: action.to}
      };
    }

    case AvailabilityFeatureActionTypes.Sites: {
      return updateActiveCustomerSiteAsset({
        ...state,        
        sites: action.assets
      });
    }

    case AvailabilityFeatureActionTypes.PIOHistory: {
      return {
        ...state,
        historyData: action.history
      };
    }

    case AvailabilityFeatureActionTypes.SetPIOHistoryVisible: {
      return {
        ...state,
        historyVisible: action.visible
      };
    }

    default:
      {
        return state;
      }
  }
}


function updateActiveCustomerSiteAsset(state: State): State {
  let currentPIOAsset = state.sites.find(site => site.id === state.activeSiteId);
  if (currentPIOAsset === undefined) {
    currentPIOAsset = state.sites[0];
  }

  if (currentPIOAsset === undefined) {
    return state;
  }

  return {
    ...state,
    activeSiteId: currentPIOAsset.id,
    fileContent: "",
    validationResult: {
      validationErrors: [],
      validatedContent: {
        legendsUtcTimes: [],
        powerDayData: []
      }
    },
    historyData: {
      legendsUtcTimes: [],
      powerDayData: []
    }
  };
}