import { Component, OnInit, HostListener, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"]
})
export class UploadFileComponent {

  dragInside = false;

  @Output()
  fileSelected = new EventEmitter<File[]>();

  onChange(event) {
    this.dragInside = false;
    if (event.target.files.length > 0) {
      this.fileSelected.emit(event.target.files);
    }

    event.target.value = "";
  }

}
