import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { PIOService } from "../../services";
import { shiftlocalDateAsUtc } from "../../../shared/utilities";

@Component({
  selector: "app-pio-history-chart",
  templateUrl: "./pio-history-chart.component.html",
  styleUrls: ["./pio-history-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PIOHistoryChartComponent implements OnDestroy {
  dataSets$: Observable<any[]> = this.pioService.pioHistoryData$.pipe(
    map(historicalData => {
      const result: any[] = [];

      result.push({
        label: "Day Ahead",
        fill: false,
        data: historicalData.map(row => ({ x: shiftlocalDateAsUtc(row.periodStart, Intl.DateTimeFormat().resolvedOptions().timeZone), y: row.value })),
        backgroundColor: "#9acdff",
        borderColor: "#9acdff",
        interpolate: true
      });

      result.push({
        label: "Intraday",
        fill: false,
        data: historicalData.map(row => ({ x: shiftlocalDateAsUtc(row.periodStart,Intl.DateTimeFormat().resolvedOptions().timeZone), y: row.intradayValue !== null ? row.intradayValue : row.value })),
        backgroundColor: "#dbb9ff",
        borderColor: "#dbb9ff",
        interpolate: true
      });

      return result;
    }));

  label$ = this.pioService.pioHistoryData$.pipe(map(rows => rows.map(row => shiftlocalDateAsUtc(row.periodStart, Intl.DateTimeFormat().resolvedOptions().timeZone))));

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,    
    animation: {
      duration: 0,
    },    
    elements: {
      point: {
        radius: 1,
        hoverRadius: 5,
      }
    },
    plugins: {
      datalabels: {
        display: false
      },
      legend: {
        display: true,
      },
      crosshair: {
        enabled: true,
        line: {
          color: "#F66",  // crosshair line color
          width: 1,   // crosshair line width
        },
        sync: {
          enabled: false
        },
        group: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
      },
      // tooltip: {
      //   enabled: true,
      //   mode: "label",
      //   intersect: false,
      //   callbacks: {
      //     label: (tooltipItem: any, data) => {
      //       let label = data.datasets[tooltipItem.datasetIndex].label || "";

      //       if (label) {
      //         label += ": ";
      //       }

      //       label += Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y * 100) / 100;

      //       if (tooltipItem.datasetIndex === 1) {
      //         const dayAheadValue = data.datasets[0].data[tooltipItem.index].y;
      //         const intradayValue = data.datasets[1].data[tooltipItem.index].y;
      //         if (dayAheadValue !== intradayValue) {
      //           label += " (" + (Math.round((intradayValue - dayAheadValue) * 100) / 100) + ")";
      //         }
      //       }

      //       return label;
      //     }
      //   }
      // }
      tooltip: {
        mode: "index",
        intersect: false
      }
    },
    layout: {
      padding: {
        top: 10
      }
    },
    scales: {
      x: {
        type: "time",
        distribution: "series",
        time: {
          unit: "minute",
          tooltipFormat: "DD-MM-YYYY HH:mm",
          displayFormats: {
            minute: "DD-MM-YYYY HH:mm"
          }
        },
        ticks: {
          beginAtZero: false,
          source: "labels",
          autoSkip: true
        },
        stacked: false,
        grid: {
          offset: false,
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: true,
        }
      },
      y: {
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: "MW",
          fontSize: 15,
        },
        ticks: {
          display: true,
          beginAtZero: true,
        },
        grid: {
          offset: false,
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true
        }
      }
    }
  };

  constructor(private readonly pioService: PIOService) {
  }

  ngOnDestroy() {
  }

}
