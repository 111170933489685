<div class="flex-row-direction flex-center-content flex-fixed-size" style="padding-top: 10px; padding-bottom: 10px; font-size: large;">        
    <mat-form-field>   
        <mat-date-range-input [rangePicker]="picker" >
            <input matInput matStartDate placeholder="Start Date" readonly [(ngModel)]="fromDate"  >
            <input matInput matEndDate placeholder="End date" readonly [(ngModel)]="toDate" (change)="getHistoryData()" (dateChange)="getHistoryData()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-date-range-picker #picker ></mat-date-range-picker>
    </mat-form-field>
</div>

<app-pio-history-chart></app-pio-history-chart>

<div class="flex-row-direction flex-end-content flex-fixed-size" style="padding: 5px;">
    <button class="download-button flex-align-start" mat-raised-button matTooltip="Download history data"
        (click)="downloadHistoryClick()">
        <i class="material-icons">
            cloud_download
        </i>
        Download
    </button>
    <button class="historical-button flex-align-start" mat-raised-button matTooltip="Back to upload"
        (click)="backClick()">
        <i class="material-icons">
            arrow_forward
        </i>
        Back
    </button>
</div>