import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-toolbar-brand-button",
  templateUrl: "./toolbar-brand-button.component.html",
  styleUrls: ["./toolbar-brand-button.component.scss"]
})
export class ToolbarBrandButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
