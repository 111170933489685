import { Injectable, Provider } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { DialogService } from "src/modules/shared/services";
import { Site } from "../state/contract";

import { SubmitPIOAction, ValidatePIOAction, ActiveSiteAction, GetPIOHistoryAction, SetPIOHistoryVisibleAction, GetSitesAction } from "../state/feature.actions";
import { State } from "../state/state";
import {
  selectValidationResult, selectResultErrors, selectActiveSite,
  selectColumns, selectCanSubmit,
  selectPowerDays, selectLegendUTCTimes, selectResultDetail, selectChanged,
  selectPowerDaysFlatten, selectColumnNames, selectHistoryData, selectHistoryVisible, selectSites
} from "../state/feature.selectors";

@Injectable({
  providedIn: "root"
})
export class PIOService {
  pioValidationResult$ = this.store.select(selectValidationResult);
  pioLegendUTCTimes$ = this.store.select(selectLegendUTCTimes);
  pioResultErrors$ = this.store.select(selectResultErrors);
  pioColumns$ = this.store.select(selectColumns);
  pioColumnNames$ = this.store.select(selectColumnNames);
  pioPowerDays$ = this.store.select(selectPowerDays);
  pioPowerDaysFlatten$ = this.store.select(selectPowerDaysFlatten);
  pioResultDetail$ = this.store.select(selectResultDetail);
  pioChanged$ = this.store.select(selectChanged);
  pioCanSubmit$ = this.store.select(selectCanSubmit);

  pioSites$ = this.store.select(selectSites);
  pioActiveSite$ = this.store.select(selectActiveSite);

  pioHistoryVisible$ = this.store.select(selectHistoryVisible);
  pioHistoryData$ = this.store.select(selectHistoryData);

  constructor(
    private readonly store: Store<State>,
    private readonly dialogService: DialogService,
  ) {    
    this.getAssets();
  }

  getAssets() {
    this.store.dispatch(new GetSitesAction());
  }

  validatePIO(fileContent: string) {
    this.store.dispatch(new ValidatePIOAction(fileContent));
  }

  submitPIOChanges() {
    this.dialogService.confirm("Submit Forecast", "Are you sure you want to submit forecast?", () => {
      this.store.dispatch(new SubmitPIOAction());
    });
  }

  changeActiveAsset(assetId: string) {
    this.store.dispatch(new ActiveSiteAction(assetId));
  }

  getPIOHistory(from: Date, to: Date) {
    this.store.dispatch(new GetPIOHistoryAction(from, to));
  }

  showHistory() {
    this.store.dispatch(new SetPIOHistoryVisibleAction(true));
  }

  hideHistory() {
    this.store.dispatch(new SetPIOHistoryVisibleAction(false));
  }

}

export const pioServiceProvider: Provider = {
  provide: PIOService,
  useClass: PIOService,
  deps: [
    Store,
    DialogService
  ]
};