import { Site } from "./contract";
import { PIOValidationResultViewModel, PIOViewModel } from "./view-model";

export interface State {
    sites: Site[];
  
    activeSiteId: string;
  
    fileContent: string;
    validationResult: PIOValidationResultViewModel;
  
    historyDates: {fromUtc: Date, toUtc: Date};
    historyVisible: boolean;
    historyData: PIOViewModel;
  }
  
export const initialState: State = { 
  sites: [],

  activeSiteId: undefined,

  fileContent: "",
  validationResult: {
    validationErrors: [],
    validatedContent: {
      legendsUtcTimes: [],
      powerDayData: []
    }
  },

  historyDates: {fromUtc: undefined, toUtc: undefined},
  historyVisible: false,
  historyData: {
    legendsUtcTimes: [],
    powerDayData: []
  }
};