import { Component, ElementRef, Input, OnDestroy, HostListener, ViewChild, AfterViewInit } from "@angular/core";
import { BarController, BarElement, CategoryScale, Chart, ChartConfiguration, ChartDataset, ChartOptions, ChartType, 
         LinearScale, LineController, LineElement, LogarithmicScale, PointElement, RadialLinearScale, registerables, 
         Scale, TimeScale, TimeSeriesScale, Title, ArcElement, BubbleController, DoughnutController, PieController, 
         PolarAreaController, RadarController, ScatterController, Decimation, Filler, Legend, Tooltip, SubTitle } from "chart.js";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import 'chartjs-adapter-moment';
import { CrosshairPlugin} from 'chartjs-plugin-crosshair';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"]
})
export class ChartComponent implements OnDestroy, AfterViewInit {

  private _chartType: ChartType = "line";
  private _labels: Array<string | string[]>;
  private _datasets: ChartDataset[];
  private _chartOptions: ChartOptions;

  private recreateChart$ = new Subject<boolean>();
  private updateChart$ = new Subject<boolean>();
  private ctx: string;
  private chart: Chart;

  @Input("chartOptions") set setChartOptions(value: ChartOptions) {
    this._chartOptions = value;
    this.updateChart();
  }

  @Input("chartType") set setChartType(value: ChartType) {
    this._chartType = value;
    this.recreateChart();
  }

  @Input("labels") set setLabels(value: Array<string | string[]>) {
    this._labels = value;
    this.updateChart();
  }

  @Input("datasets") set setChartData(value: ChartDataset[]) {
    this._datasets = value;
    this.updateChart();
  }

  @ViewChild("chartCanvas", { static: true, read: ElementRef }) chartCanvas;

  constructor() {
  }

  ngAfterViewInit() {
    this.updateChart$.pipe(debounceTime(100)).subscribe(() => {
      if (this.chart) {
        this.chart.data.labels = this._labels;
        this.chart.data.datasets = this._datasets;
        this.chart.update();
      }
    });

    this.recreateChart$.pipe(debounceTime(400)).subscribe(() => {
      Chart.register(ArcElement, BubbleController, DoughnutController, PieController, PolarAreaController, RadarController,
                     ScatterController,  CategoryScale, LogarithmicScale, PointElement, LinearScale,
                     TimeScale, TimeSeriesScale, BarController, BarElement, RadialLinearScale, LineController, LineElement, 
                     CrosshairPlugin, ChartDataLabels, Title, Decimation, Filler, Legend, Title, Tooltip, SubTitle);

      this.releaseChart();
      this.ctx = this.chartCanvas.nativeElement;

      this.chart = new Chart(this.ctx, {
        type: this._chartType,
        options: this._chartOptions,
        data: {
          labels: this._labels,
          datasets: this._datasets
        }
      });

    });

    this.recreateChart();
  }

  private recreateChart = () => this.recreateChart$.next(true);

  private updateChart = () => this.updateChart$.next(true);

  private releaseChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  public ngOnDestroy() {
    this.releaseChart();
  }

}
