import { Inject, Injectable, Provider } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppInsights } from "applicationinsights-js";
import { filter, map } from "rxjs/operators";
import { SharedConfiguration } from "../models/configuration";

export interface StringPropertiesInterface {
  [name: string]: string;
}

@Injectable({
  providedIn: "root"
})
export class AppInsightService {

  constructor(private readonly router: Router, @Inject(SharedConfiguration) private readonly config: SharedConfiguration) {
    if (!AppInsights.config) {
      const appInsightConfig: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: this.config.appInsightInstrumentKey,
        enableCorsCorrelation: true
      };

      AppInsights.downloadAndSetup(appInsightConfig);
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd))
      .subscribe(event => {
        this.logPageView(event.url, event.url);
      });
  }

  logPageView(name?: string, url?: string, properties: StringPropertiesInterface = {}, measurements?: { [name: string]: number; }, duration?: number) {
    if (properties && properties.value) {
      const value: string = properties.value;
      if (value.startsWith("/#access_token")) {
        return;
      }
    }
    try {
      AppInsights.trackPageView(name, url, properties, measurements, duration);
    } catch (error) {
      console.error(error);
    }
  }

  logEvent(name: string, properties?: StringPropertiesInterface, measurements?: { [name: string]: number; }) {
    try {
      AppInsights.trackEvent(name, properties, measurements);
    } catch (error) {
      console.error(error);
    }
  }

  logTrace(message: string, properties?: StringPropertiesInterface, securityLevel?: number) {
    try {
      AppInsights.trackTrace(message, properties, securityLevel);
    } catch (error) {
      console.error(error);
    }
  }

  logException(exp: Error, handledAt: string = "", properties: StringPropertiesInterface = {}, measurements?: { [name: string]: number; }, severityLevel?: number) {    
    try {
      AppInsights.trackException({ name: exp ? "Undefined exp variable in logException" : exp?.name, message: "Error" }, handledAt, properties, measurements, severityLevel);
    } catch (error) {
      console.error(error);
    }
  }

  trackDependency(id: string, method: string, absoluteUrl: string, pathName: string, totalTime: number, success: boolean, resultCode: number) {
    try {
      AppInsights.trackDependency(id, method, absoluteUrl, pathName, totalTime, success, resultCode);
    } catch (error) {
      console.error(error);
    }
  }

  setAuthenticatedUserId(userId: string): void {
    AppInsights.setAuthenticatedUserContext(userId);
  }
}

export const appInsightServiceProvider: Provider = {
  provide: AppInsightService,
  useClass: AppInsightService,
  deps: [Router, SharedConfiguration]
};
