import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./modules/app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    // if ("serviceWorker" in navigator && environment.production) {
    //   navigator.serviceWorker.register("ngsw-worker.js");
    // }
  })
  .catch(err => {
    // tslint:disable-next-line:no-console
    console.log("Error in startup :", err);
  });
