import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./state";
import { flatMap, flatten } from "lodash";
import { PIOMarketCategoryEnum } from "./contract";

export const featureName = "availability";
export const selectForecastFeature = createFeatureSelector<State>(featureName);

export const selectSites = createSelector(
    selectForecastFeature,
    (state: State) => state.sites
);

export const selectActiveSite = createSelector(
    selectForecastFeature,
    (state: State) => state.sites.find(site => site.id === state.activeSiteId)
);

export const selectHistoryDates = createSelector(
    selectForecastFeature,
    (state: State) => state.historyDates
);

export const selectFileContent = createSelector(
    selectForecastFeature,
    (state: State) => state.fileContent
);

export const selectValidationResult = createSelector(
    selectForecastFeature,
    (state: State) => state.validationResult
);

export const selectChanged = createSelector(
    selectValidationResult,
    validationResult => validationResult.validatedContent.powerDayData.length > 0
);

export const selectColumns = createSelector(
    selectValidationResult,
    validationResult => validationResult.validatedContent.powerDayData.map(powerDay => ({ powerDayStart: powerDay.powerDayStartUtc, powerDayCET: powerDay.powerDayStartCET }))
);

export const selectColumnNames = createSelector(
    selectColumns,
    columns => ["timeUTC"].concat(columns.map(column => column.powerDayCET))
);

export const selectLegendUTCTimes = createSelector(
    selectValidationResult,
    validationResult => validationResult.validatedContent.legendsUtcTimes
);

export const selectPowerDays = createSelector(
    selectValidationResult,
    validationResult => validationResult.validatedContent.powerDayData //.sort((a, b) => new Date(a.powerDayStartUtc) > new Date(b.powerDayStartUtc) ? 1 : -1)
);

export const selectResultDetail = createSelector(
    selectPowerDays,
    selectColumns,
    selectLegendUTCTimes,
    (powerDays, columns, legendUTCTimes) =>
        legendUTCTimes.map((legendUTCTime, index) => {
            const result = {
                timeUTC: legendUTCTime
            };

            columns.forEach(column => {
                const powerDay = powerDays.find(o => o.powerDayStartUtc === column.powerDayStart);
                if (powerDay) {
                    result[column.powerDayCET] = powerDay.details.find(o => o.legendIndex === index);
                } else {
                    result[column.powerDayCET] = {};
                }
            });

            return result;
        })
);

export const selectResultErrors = createSelector(
    selectValidationResult,
    validationResult => validationResult.validationErrors
);

export const selectPowerDaysFlatten = createSelector(
    selectPowerDays,
    powerDays => flatMap(flatMap(powerDays).map(o => o.details)).sort((a, b) => a.periodStart > b.periodStart ? 1 : -1)
);

export const selectCanSubmit = createSelector(
    selectPowerDaysFlatten,
    selectResultErrors,
    (pioPowerDaysFlatten, errorList) =>
        errorList.length === 0
        && pioPowerDaysFlatten.length > 0
        && pioPowerDaysFlatten.filter(o => o.marketCategory !== PIOMarketCategoryEnum.Expired && o.marketCategory !== PIOMarketCategoryEnum.Unknown).length > 0
);

export const selectHistoryVisible = createSelector(
    selectForecastFeature,
    state => state.historyVisible
);

export const selectHistoryData = createSelector(
    selectForecastFeature,
    state => flatten(state.historyData.powerDayData.map(p => p.details))
);