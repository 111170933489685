<button mat-button [matMenuTriggerFor]="userMenu" [matTooltip]="fullName" class="toolbar-button">
  <mat-icon>account_circle</mat-icon>
  <div style="font-size: small; color: gray; line-height: 25px; height: 25px;">
    {{ fullName }}
  </div>
</button>

<mat-menu #userMenu="matMenu">
  <button mat-menu-item (click)="logout()" matTooltip="Click to logout">
    <mat-icon>exit_to_app</mat-icon> {{ userName }} ({{ fullName }})
  </button>
</mat-menu>