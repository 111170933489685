import { Injectable, Provider } from "@angular/core";
import { interval } from "rxjs";
import { map, startWith, share, filter } from "rxjs/operators";
import { formatDateToLocal } from "../utilities/index";

@Injectable({
  providedIn: "root"
})
export class DateService {

  thirtySecondTimer$ = interval(1000).pipe(
    filter(() => new Date().getSeconds() % 30 === 0),
    share()
  ).pipe(startWith(0));


  currentUKLocalDate$ = this.thirtySecondTimer$.pipe(map(() => formatDateToLocal(new Date(), "Europe/London", "DD-MM-YYYY")));
  currentUKLocalTime$ = this.thirtySecondTimer$.pipe(map(() => formatDateToLocal(new Date(), "Europe/London", "HH:mm")));

  constructor() {
  }
}

export const dateServiceProvider: Provider = {
  provide: DateService,
  useClass: DateService,
  deps: []
};
