import { NgModule } from "@angular/core";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { EffectsModule } from "@ngrx/effects";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../../environments/environment";
import { CommunicationModule } from "../communication/communication.module";
import { AvailabilityModule } from "../availability/availability.module";
import { SharedModule } from "../shared/shared.module";
import {
  AppRootComponent, HomeComponent, PageNotFoundComponent,
  ToolbarBrandButtonComponent, ToolbarComponent, ToolbarSettingsMenuComponent
} from "./components";
import { appServiceProvider } from "./services";
import { MY_Data_FORMATS } from "./state/utilities";

const routes: Routes = [{ path: "**", redirectTo: "", pathMatch: "full" }];

@NgModule({
  declarations: [
    AppRootComponent, ToolbarComponent, HomeComponent, ToolbarBrandButtonComponent,
    PageNotFoundComponent, ToolbarSettingsMenuComponent
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: !environment.production }),    
    StoreModule.forRoot({
      router: routerReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: environment.production ? 25 : 200, // Retains last states
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    AvailabilityModule,
    SharedModule.forRoot({
      appInsightInstrumentKey: environment.appInsights.instrumentationKey,
      applicationVersion: environment.application_version
    }),
    CommunicationModule
  ],
  providers: [    
    appServiceProvider,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: MAT_DATE_FORMATS, useValue: MY_Data_FORMATS }
  ],
  bootstrap: [AppRootComponent]
})
export class AppModule {
}
