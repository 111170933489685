<div class="pio-container">
  <div class="select-customer-row">
      <mat-select placeholder="Asset" (selectionChange)="onActiveSiteChanged($event.value)"
          [value]="(pioService.pioActiveSite$ | async)?.id">
            <mat-option *ngFor="let site of pioService.pioSites$ | async" [value]="site.id">
              {{ site.customerName + " - " + site.name}}
            </mat-option>
        </mat-select>
  </div>

  <app-pio-submit *ngIf="(pioService.pioHistoryVisible$ | async) === false">
  </app-pio-submit>

  <app-pio-history *ngIf="(pioService.pioHistoryVisible$ | async) === true">
  </app-pio-history>

</div>