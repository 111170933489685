export type Guid = string;

export const newGuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g,
        (placeHolder) => {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0;
            // tslint:disable-next-line:no-bitwise
            const v = placeHolder === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
};
